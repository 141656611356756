
import request from "@/utils/request";
//添加订阅
export const AddSubscribe = (obj) => {
    return request.post("/api/Subscribe/Add", obj);
};
//取消订阅
export const DeleteSubscribe = (obj) => {
    return request.post("/api/Subscribe/Delete", obj);
};  

//是否订阅
export const IsSubscribe = (obj) => {
    return request.post("/api/Subscribe/IsSubscribe", obj);
}; 

//我的订阅
export const MySubscribe = () => {
    return request.get("/api/Subscribe/MySubscribe");
}; 