<template>
  <div class="container">
    <!-- <van-nav-bar left-arrow title="编辑资料" right-text="保存"  @click-right="saveUser" @click-left="$router.back()"></van-nav-bar> -->
    <van-nav-bar title="消息通知设置" left-arrow @click-left="$router.back()" />
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    >
    <van-notice-bar v-if="!loading && wxuserStatus!=2"  mode="link" @click="gobindwx">未绑定微信，或未关注中酿IT服务号</van-notice-bar>
    <van-cell-group inset v-if="!loading" title="微信通知">
      <van-cell
        v-for="(item,index)  in subcribeList"
        :key="item.itemType.toString()"
        :title="item.typeName"
        v-show="item.itemType<200" 
      >
        <van-switch :value="subcribeSwitchList[index]" @input="ChangeValue(index)" size="24px"  />
      </van-cell>
    </van-cell-group>

    <van-cell-group inset v-if="!loading" title="KM内通知">
      <van-cell
        v-for="(item,index)  in subcribeList"
        :key="item.itemType.toString()"
        :title="item.typeName"
        v-show="item.itemType>200" 
      >
        <van-switch :value="subcribeSwitchList[index]" @input="ChangeValue(index)" size="24px"   />
      </van-cell>
    </van-cell-group>
  </div>
</template>
    
    <script>
//import { changePassWord } from "@/api/user";
import { AddSubscribe, DeleteSubscribe, MySubscribe } from "@/api/subscribe";
import { GetWXUserStatus } from "@/api/wxuser";
import { Toast } from "vant";

export default {
  name: "user-MySubscribe",
  data() {
    return {
      loading: true,
      subcribeList: [],
      subcribeSwitchList:[],
      wxuserStatus:2,
    };
  },
  created() {
    this.getwxstatus();
    this.init();
  },
  methods: {
    async getwxstatus(){
        let wxuserStatusResult = await GetWXUserStatus();
        if (wxuserStatusResult.data.success) {
          this.wxuserStatus = wxuserStatusResult.data.data;
        }
    },

    //订阅状态初始化化
    async init() {
      try {
        const data = await MySubscribe();
        this.subcribeSwitchList=[];
        if (data.data.success) {
          this.subcribeList = data.data.data;
          this.loading=false;
          for (let index = 0; index < this.subcribeList.length; index++) {
            if(this.subcribeList[index].isSystemDefaultValue){
                this.subcribeSwitchList.push(this.subcribeList[index].systemDefaultValue)
            }
            else{
                this.subcribeSwitchList.push(!this.subcribeList[index].systemDefaultValue)
            }
          }
        } else {
          Toast(data.data.errorMessage);
        }
      } catch (error) {
        console.log(error);
      }
    },

    //订阅状态修改
    async ChangeValue(sindex) {
      var subpara = {
        itemId: "",
        itemType: 0,
        typeName: "0",
      };
      subpara.itemType = this.subcribeList[sindex].itemType;
      subpara.typeName = this.subcribeList[sindex].typeName;
      if (this.subcribeList[sindex].isSystemDefaultValue) {
        //新增
         await AddSubscribe(subpara);
      } else {
        //删除
         await DeleteSubscribe(subpara);
      }
      this.init();
    },
    gobindwx(){
        this.$router.replace({ path: "/user/bindwxpre" });
    }
  },
};
</script>
    
    <style lang="less" scoped>
    .loading {
  text-align: center;
}
.btn-box {
  padding: 20px;
}
.van-cell-group__title {
    padding: 16px 16px 8px;
    color: #000; 
    font-size: 19px;
    line-height: 30px;
}
</style>
    