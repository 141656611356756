import request from '@/utils/request'

// 
export function GetAppId () {
    return request({
      url: '/api/WXUser/GetAppID',
      method: 'get',
    })
  }

export function GetWXUserInfo(code){
  return request({
    url:`/api/WXUser/GetWXUserInfo?code=${code}`,
    method:'get'
  })
}
//获取用户绑定微信状态
export function GetWXUserStatus(){
  return request.get("/api/WXUser/GetWXUserStatus")
}

export function GetAppJsConfig(url){
  return request({
    url:`/api/WXUser/GetAppJsConfig?url=${url}`,
    method:'get'
  })
}

export function GetWXMiniAppJsConfig(url){
  return request({
    url:`/api/WXMini/GetAppJsConfig?url=${url}`,
    method:'get'
  })
}

///api/WXMini/GetWXopenlink
export function GetWXopenlink(query){
  return request({
    url:`/api/WXMini/GetWXopenlink?query=${query}`,
    method:'get'
  })
}




export function BingWXUser (data) {
  return request({
    url: '/api/WXUser/Bind',
    data,
    method: 'post'
  })
}

export function UnbindingWXUser(id){
  return request.get(`/api/WXUser/Unbinding?id=${id}`)
}